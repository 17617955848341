import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardHeader from '@mui/material/CardHeader';
import Box from '@mui/material/Box';
import AiStarIcon from '../../assets/ai-star.svg';
import Collapse from '@mui/material/Collapse';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {useFlags, withLDConsumer} from 'launchdarkly-react-client-sdk';
import _ from 'lodash';
import {markItemDismissed, markItemReviewed, showActionError, showActionSuccess} from '../../data/copilotV1';
import {BLACK_100, GREEN_95} from '../../theme';
import {OverlayTrigger, Popover} from 'react-bootstrap';
import {useDismissDiscrepancyMutation} from '../../data/copilotV1Api';
import Loading from '../Loading';
import {sleep, trackEvent} from '../../util';

const styles = {
  card: {
    itemLabel: {
      color: BLACK_100,
      fontSize: '0.875rem',
      fontWeight: 700,
      lineHeight: '1.25rem',
    },
    itemSubLabel: {
      color: '#9F7119',
      fontSize: '0.75rem',
      fontWeight: 500,
      lineHeight: '1.25rem',
    },
    discrepancyLabel: {
      color: BLACK_100,
      fontSize: '0.75rem',
      fontWeight: 500,
      lineHeight: '1.25rem',
    },
    discrepancyValue: {
      color: BLACK_100,
      fontSize: '0.875rem',
      fontWeight: 700,
      lineHeight: '1.25rem',
    },
    footingShouldBeLabel: {
      color: BLACK_100,
      fontSize: '0.75rem',
      fontWeight: 500,
      lineHeight: '1.25rem',
      fontStyle: 'italic',
    },
  },
  dismiss: {
    header: {
      color: BLACK_100,
      fontSize: '0.875rem',
      fontWeight: 700,
      lineHeight: '1.25rem',
    },
    formControlLabel: {
      '.MuiFormControlLabel-label': {
        color: BLACK_100,
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: '1.25rem',
      },
      marginRight: 0,
      alignItems: 'flex-start',
      '.MuiRadio-root': {
        paddingLeft: 0,
        paddingRight: '0.5rem',
        paddingY: 0,
      },
    },
    textField: {
      '.MuiOutlinedInput-root': {
        height: '2rem',
        borderRadius: '0.25rem',
        border: '1px solid #DDE0DE',
        paddingY: 0,
        '&.Mui-focused fieldset': {
          borderColor: GREEN_95,
        },
      },
    },
  },
  component: {
    button: {
      borderRadius: '0.5rem',
      border: '1px solid #0F1E24;',
      minHeight: '2rem',
    },
    dismissButton: {
      borderRadius: '0.5rem',
      border: '1px solid #DDE0DE;',
      paddingHorizontal: '0.75rem',
      paddingVertical: '0.375rem',
      minWidth: 'inherit',
      '.MuiButton-startIcon': {
        marginRight: '0.25rem',
        marginLeft: 0,
      },
    },
    buttonLabel: {
      color: BLACK_100,
      fontSize: '0.75rem',
      fontWeight: 500,
      lineHeight: '1.25rem',
    },
  },
}

const DEFAULT_DISMISS_REASON = 'no_reason';

const CopilotCard = ({documentId, setOpenItem, openItem, idx, item, isLoading, flags}) => {
  const {useMockedCopilotData} = useFlags();
  const dispatch = useDispatch();
  const [dismissReason, setDismissReason] = useState(DEFAULT_DISMISS_REASON);
  const [dismissOtherDetail, setDismissOtherDetail] = useState('');
  const [dismissDiscrepancy, {isLoading: isDismissLoading, isSuccess: isDismissSuccess, isError: isDismissError, data: dismissData, error: dismissError}] = useDismissDiscrepancyMutation();
  const [showDismissal, setShowDismissal] = useState(false);
  const [isProcessingDismissal, setProcessingDismissal] = useState(false);
  const [dismissStartTimestamp, setDismissStartTimestamp] = useState(null);
  const copilot = useSelector(state => state.copilot);
  const {currentOrg} = useSelector(state => state.session);

  useEffect(() => {
    if (isDismissSuccess) {
      console.log('Discrepancy dismissed', {jobId: copilot.jobId, org: currentOrg.orgId, result: dismissData});
      setProcessingDismissal(false);
      setDismissReason(DEFAULT_DISMISS_REASON);
      setDismissOtherDetail('');
      dispatch(markItemDismissed({id: item.id, copilotActionMessage: 'Thank you for your feedback!'}));
      trackEvent('Dismissed Discrepancy Completed', {jobId: copilot.jobId, discrepancyId: item.id, reason: dismissReason, otherReason: dismissOtherDetail}, new Date().getTime() - dismissStartTimestamp);
      setDismissStartTimestamp(null);
      setShowDismissal(false);
    } else if (isDismissError) {
      console.error('Error dismissing discrepancy', {jobId: copilot.jobId, org: currentOrg.orgId, error: dismissError});
      setProcessingDismissal(false);
      trackEvent('Dismissed Discrepancy Failed', {jobId: copilot.jobId, discrepancyId: item.id, reason: dismissReason, otherReason: dismissOtherDetail, error: dismissError.status}, new Date().getTime() - dismissStartTimestamp);
      setDismissStartTimestamp(null);
      dispatch(showActionError({copilotErrorMessage: 'There was an error recording your response. Please try again later.'}));
    }
  }, [isDismissError, isDismissSuccess]);

  const renderCard = (card) => {
    const _opacity = isLoading ? {opacity: 0.6} : null;

    if (card.type === 'discrepancies') {
      return (
        <Stack direction={'column'} spacing={'1rem'} flex={1}>
          <Typography style={styles.card.itemSubLabel}>has inconsistent values:</Typography>
          {_.map(card.items, (_item, _idx) => {
            return (
              <Stack direction={'column'} spacing={'0.5rem'} key={_idx}>
                <Typography style={{...styles.card.discrepancyLabel, ..._opacity}}>Pg. {_item.page_number}: {_item.source === 'table' ? 'Table' : _item.text}</Typography>
                <Box width={'fit-content'} paddingY={'auto'} paddingX={'0.375rem'} bgcolor={'rgba(245, 193, 92, 0.30)'} borderRadius={'0.75rem'} sx={_opacity}>
                  <Typography style={styles.card.discrepancyValue}>{_item.value || _item.date}</Typography>
                </Box>
              </Stack>
            )
          })}
        </Stack>
      )
    } else if (card.type === 'footings') {
      return (
        <Stack direction={'column'} spacing={'1rem'} flex={1}>
          <Typography style={styles.card.itemSubLabel}>Does not foot:</Typography>
          <Stack direction={'column'} spacing={'0.5rem'}>
            <Typography style={{...styles.card.discrepancyLabel, ..._opacity}}>Pg. {card.table_page_number}: Table</Typography>
            <Box width={'fit-content'} paddingY={'auto'} paddingX={'0.375rem'} bgcolor={'rgba(245, 193, 92, 0.30)'} borderRadius={'0.75rem'} sx={_opacity}>
              <Typography style={styles.card.discrepancyValue}>{card.presented_value}</Typography>
            </Box>
            <Typography style={{...styles.card.footingShouldBeLabel, ..._opacity}}>The value should be</Typography>
            <Box width={'fit-content'} paddingY={'auto'} paddingX={'0.375rem'} bgcolor={'#DDE0DE'} borderRadius={'0.75rem'} sx={_opacity}>
              <Typography style={styles.card.discrepancyValue}>{card.calculated_value}</Typography>
            </Box>
          </Stack>
        </Stack>
      )
    }
  }

  const _handleDismissReasonChange = event => {
    setDismissReason(event.target.value);
    setDismissOtherDetail('');
  }

  const _popover = (
    <Popover id="popover-basic">
      <Popover.Body style={{padding: 0}}>
        <Stack direction={'column'} spacing={'0.75rem'}>
          <Stack direction={'column'} spacing={'1rem'} padding={'1rem'}>
            <Typography style={styles.dismiss.header}>Dismiss for a reason?</Typography>
            <RadioGroup
              name="dismiss-buttons-group"
              value={dismissReason}
              onChange={_handleDismissReasonChange}
            >
              <Stack direction={'column'} spacing={'1rem'}>
                <FormControlLabel sx={styles.dismiss.formControlLabel} value="no_reason" control={<Radio size={'small'}/>} label="No reason" />
                <FormControlLabel sx={styles.dismiss.formControlLabel} value="value_not_present" control={<Radio size={'small'}/>} label="Displayed value isn’t present in my document" />
                <FormControlLabel sx={styles.dismiss.formControlLabel} value="calculation_incorrect" control={<Radio size={'small'} />} label="Calculation is incorrect" />
                <Stack direction={'column'} spacing={'0.375rem'}>
                  <FormControlLabel sx={styles.dismiss.formControlLabel} value="other" control={<Radio size={'small'} />} label="Other reason" />
                  {dismissReason === 'other' && (
                    <Box paddingLeft={'1.5rem'}>
                      <TextField
                        sx={styles.dismiss.textField}
                        variant={'outlined'}
                        fullWidth={true}
                        value={dismissOtherDetail}
                        onChange={event => setDismissOtherDetail(event.target.value)}
                        placeholder={'Share details (optional) ...'}
                      />
                    </Box>
                  )}
                </Stack>
              </Stack>
            </RadioGroup>
          </Stack>
        </Stack>
        <Divider orientation={'horizontal'} sx={{height: 0.0625, opacity: '100'}} />
        <Box display={'flex'} padding={'1rem'}>
          <Button
            variant={'outlined'}
            fullWidth={false}
            sx={styles.component.button}
            onClick={() => {
              setProcessingDismissal(true);
              setDismissStartTimestamp(new Date().getTime());
              const _data = {
                document_id: documentId,
                dismissed_cards: {
                  footings: [],
                  discrepancies: [],
                },
              };
              _data.dismissed_cards[item.type] = [{
                id: item.id,
                reason: dismissReason,
                other_reason: dismissOtherDetail,
              }];

              if (useMockedCopilotData) {
                sleep(_.random(1000, 2000)).then(() => {
                  setProcessingDismissal(false);
                  setDismissReason(DEFAULT_DISMISS_REASON);
                  setDismissOtherDetail('');
                  dispatch(markItemDismissed({id: item.id, copilotActionMessage: 'Thank you for your feedback!'}));
                  trackEvent('Dismissed Discrepancy Completed', {jobId: copilot.jobId, discrepancyId: item.id, reason: dismissReason, otherReason: dismissOtherDetail}, new Date().getTime() - dismissStartTimestamp);
                  setDismissStartTimestamp(null);
                  setShowDismissal(false);
                });
              } else {
                dismissDiscrepancy({
                  data: _data,
                });
              }
            }}
          >
            <Loading loading={isProcessingDismissal} />
            {!isProcessingDismissal && (
              <Typography style={styles.component.buttonLabel}>Dismiss</Typography>
            )}
          </Button>
        </Box>
      </Popover.Body>
    </Popover>
  )

  return (
    <Card variant={'outlined'}>
      <CardActionArea
        disableRipple
        disableTouchRipple
        sx={{
          '.MuiCardActionArea-focusHighlight': {
            background: 'transparent',
          },
        }}
        onClick={() => setOpenItem(idx)}
      >
        <CardHeader
          sx={{
            '.MuiCardHeader-avatar': {
              marginRight: '0.5rem',
            },
            '.MuiCardHeader-title': styles.card.itemLabel,
            paddingBottom: openItem === idx ? 0 : '1rem',
          }}
          avatar={<Box width={'1.25rem'} height={'1.25rem'} display={'flex'} justifyContent={'center'} alignItems={'center'} borderRadius={'0.625rem'} border={'1px solid #33B980'} bgcolor={'#F1F4F2'}><img src={AiStarIcon} alt={''}/></Box>}
          title={item.line_item}
        />
      </CardActionArea>
      <Collapse in={openItem === idx} timeout="auto" unmountOnExit>
        <CardContent sx={{
          paddingY: 0,
          '&:last-child': {
            paddingBottom: '1rem',
          },
        }}>
          <Stack direction={'column'} spacing={'1.5rem'}>
            <Stack direction={'row'} spacing={'0.5rem'}>
              <Box display={'flex'} flexDirection={'row'} padding={'0.625rem 0.625rem 0 0.625rem'}>
                <Divider orientation={'vertical'} sx={{height: '100%', opacity: 100}} />
              </Box>
              {renderCard(item)}
            </Stack>
          </Stack>
        </CardContent>
        <CardActions sx={{padding: '1rem'}}>
          <Stack direction={'row'} spacing={'0.5rem'} flex={1}>
            <Button variant={'outlined'} fullWidth={true} startIcon={<CheckIcon sx={{width: '1rem', height: '1rem'}} />} sx={styles.component.button} onClick={() => {
              dispatch(showActionSuccess());
              dispatch(markItemReviewed({id: item.id}));
            }} disabled={isLoading || isDismissLoading}>
              <Typography style={styles.component.buttonLabel}>Mark resolved</Typography>
            </Button>
            {flags.copilotDismissCard && (
              <Tooltip
                title={'Dismiss suggestion'}
                placement={'top'}
              >
                <Box>
                  <OverlayTrigger
                    trigger="click"
                    placement="bottom-end"
                    flip={true}
                    rootClose
                    overlay={_popover}
                    show={showDismissal}
                    onToggle={() => setShowDismissal(!showDismissal)}
                  >
                    <Button
                      variant={'outlined'}
                      fullWidth={false}
                      disabled={isLoading || isDismissLoading}
                      sx={styles.component.dismissButton}
                      startIcon={<CloseIcon sx={{width: '1rem', height: '1rem'}} />}
                    >
                      <Typography style={styles.component.buttonLabel}>Dismiss</Typography>
                    </Button>
                  </OverlayTrigger>
                </Box>
              </Tooltip>
            )}
          </Stack>
        </CardActions>
      </Collapse>
    </Card>
  )
}

CopilotCard.propTypes = {
  documentId: PropTypes.string.isRequired,
  setOpenItem: PropTypes.func.isRequired,
  openItem: PropTypes.number.isRequired,
  idx: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  flags: PropTypes.shape({
    copilotDismissCard: PropTypes.bool.isRequired,
  }).isRequired,
};

export default withLDConsumer()(CopilotCard);
