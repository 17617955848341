import {createSlice} from '@reduxjs/toolkit';
import spacetime from 'spacetime';
import _ from 'lodash';
import hash from 'object-hash';

const CHECK_INITIAL_STATE = {
  jobId: null,
  asOf: null,
  items: null,
  openItems: null,
  numItems: 0,
  numOpenItems: 0,
  isFetching: false,
  isError: false,
  errorMessage: null,
  isVisible: true,
}

const INITIAL_STATE = {
  documentId: null,
  numOpenItems: 0,
  copilotActionMessage: null,
  copilotActionSuccess: false,
  copilotReviewComplete: false,
  copilotActionError: false,
  copilotErrorMessage: null,
  footings: _.clone(CHECK_INITIAL_STATE),
  inconsistencies: _.clone(CHECK_INITIAL_STATE),
}

// TODO: v2 Remove once use-new-ai-api-contract can be removed
const _parseListOfInconsistencies = ({inconsistencies}) => {
  const _inconsistencies = _.keyBy(_.map(inconsistencies, _inconsistency => {
    const _items = _.sortBy(_.map(_inconsistency.items, _item => ({
      ..._item,
    })), 'page_number');

    return {
      ..._inconsistency,
      items: _items,
      pageNumber: _items[0].page_number,
      page_number: _items[0].page_number,
      resolved: false,
      type: 'inconsistencies',
    }
  }), 'id');
  if (process.env.NODE_ENV === 'development') {
    console.log('parseListOfInconsistencies', _inconsistencies)
  }
  return _inconsistencies;
}

const _parseListOfInconsistenciesV1 = (inconsistencies) => {
  const _inconsistencies = {};
  _.forEach(_.cloneDeep(_.filter(inconsistencies.with_discrepancies, {dismissed: false})), _inconsistency => {
    // _inconsistency.id = crypto.randomUUID();
    _inconsistency.resolved = false;
    _inconsistency.type = 'inconsistencies';
    const _itemDiscrepancies = {};
    _.forEach(_inconsistency.list_of_discrepancies, (_values, _key) => {
      _.forEach(_values, _item => {
        _.forEach(_item.items, _item => {
          _itemDiscrepancies[hash(_item)] = _item;
          _item.id = _.get(_item, 'table_id', _item.id);
        })
      });
    })
    _inconsistency.items = _.sortBy(_.values(_itemDiscrepancies), 'page_number');
    _inconsistency.pagenumber = _inconsistency.items[0].page_number;
    _inconsistencies[_inconsistency.id] = _inconsistency;
  });
  if (process.env.NODE_ENV === 'development') {
    console.log('parseListOfInconsistencies', _inconsistencies)
  }
  return _inconsistencies;
}

const _parseFootingIssues = (footingIssues) => {
  const _items = {};
  const _openItems = {};
  _.forEach(_.cloneDeep(_.filter(footingIssues, {dismissed: false})), _footingIssue => {
    _.forEach(_footingIssue.footings, _footing => {
      if (_footing.dismissed) {
        return;
      }
      if (_footing.calculated_value === '' && _footing.presented_value === '') {
        return;
      }
      const _item = {
        ..._footing,
        ..._.pick(_footingIssue, ['table_id', 'table_page_number']),
        type: 'footings',
        resolved: false,
        pageNumber: _footingIssue.page_number,
        tableId: _footingIssue.table_id,
        id: _footingIssue.table_id,
      }
      _items[_item.id] = _item;
      if (!_item.is_footing_correct) {
        _openItems[_item.id] = _item;
      }
    });
  });
  if (process.env.NODE_ENV === 'development') {
    console.log('parseFootingIssues', {items: _items, openItems: _openItems});
  }
  return {items: _items, openItems: _openItems};
}

const _setPayload = (checkType, state, jobId, data, isSuccess, isFetching, isError, error, v2) => {
  const _payload = state;
  if (!_.isNil(jobId)) {
    _payload.jobId = jobId;
  }
  if (!_.isNil(isSuccess)) {
    _payload.isSuccess = isSuccess;
  }
  if (!_.isNil(isFetching)) {
    _payload.isFetching = isFetching;
  }
  if (!_.isNil(isError)) {
    _payload.isError = isError;
  }
  _payload.errorMessage = error;

  if (!isFetching && isSuccess) {
    _payload.asOf = spacetime.now().epoch;
    if (checkType === 'footings') {
      const {items, openItems} = _parseFootingIssues(data);
      _payload.items = items;
      _payload.openItems = openItems;
      _payload.numItems = _.values(items).length;
      _payload.numOpenItems = _.values(openItems).length;
    } else {
      if (v2) {
        const items = _parseListOfInconsistencies(data);
        _payload.items = items;
        _payload.openItems = items;
        _payload.numItems = _.values(items).length;
        _payload.numOpenItems = _.values(items).length;
      } else {
        const items = _parseListOfInconsistenciesV1(data);
        _payload.items = items;
        _payload.openItems = items;
        _payload.numItems = _.values(items).length;
        _payload.numOpenItems = _.values(items).length;
      }
    }
  }

  return _payload;
}

export const copilotSlice = createSlice({
  name: 'copilot',
  initialState: INITIAL_STATE,
  reducers: {
    hideActionError: (state) => {
      state.copilotActionError = false;
      state.copilotErrorMessage = null;
    },
    hideReviewComplete: (state) => {
      state.copilotReviewComplete = false;
    },
    hideActionSuccess: (state) => {
      state.copilotActionSuccess = false;
      state.copilotActionMessage = null;
    },
    markItemDismissed: (state, action) => {
      const {checkType, id, copilotActionMessage} = action.payload;
      const _payload = state[checkType];
      const _items = _payload.items;
      const _openItems = _payload.openItems;
      delete _items[id];
      delete _openItems[id];
      _payload.numOpenItems = _.values(_openItems).length;
      _payload.numItems = _.values(_items).length;
      _payload.items = _items;
      _payload.openItems = _openItems;
      state[checkType] = _payload;
      state.copilotActionMessage = copilotActionMessage;
      state.copilotActionSuccess = true;
    },
    markItemReviewed: (state, action) => {
      const {checkType, id} = action.payload;
      const _payload = state[checkType];
      const _items = _payload.items;
      const _openItems = _payload.openItems;
      _items[id].resolved = true;
      delete _openItems[id];
      _payload.numOpenItems = _.values(_openItems).length;
      _payload.items = _items;
      _payload.openItems = _openItems;
      state[checkType] = _payload;
      state.copilotActionSuccess = true;
    },
    setDocumentId: (state, action) => {
      state.documentId = action.payload.documentId;
    },
    setPayload: (state, action) => {
      const {checkType, jobId, data, isSuccess, isFetching, isError, error, v2} = action.payload;
      state[checkType] = _setPayload(checkType, state[checkType], jobId, data, isSuccess, isFetching, isError, error, v2);
      if (isSuccess) {
        state.copilotReviewComplete = true;
        state.numOpenItems = state.footings.numOpenItems + state.inconsistencies.numOpenItems;
      }
    },
    setIsVisible: (state, action) => {
      const {checkType, isVisible} = action.payload;
      state[checkType].isVisible = isVisible;
    },
    showActionError: (state, action) => {
      state.copilotActionError = true;
      state.copilotErrorMessage = _.get(action, 'payload.copilotErrorMessage', null);
    },
    showActionSuccess: (state, action) => {
      state.copilotActionSuccess = true;
      state.copilotActionMessage = _.get(action, 'payload.copilotActionMessage', null);
    },
    reset: () => INITIAL_STATE,
  },
})

// Action creators are generated for each case reducer function
export const {hideActionError, hideActionSuccess, hideReviewComplete, markItemDismissed, markItemReviewed, reset, setDocumentId, setIsVisible, setPayload, showActionError, showActionSuccess} = copilotSlice.actions;

export default copilotSlice.reducer;
