import React, {useState, useEffect, useRef, useCallback} from 'react'
import {connect, useDispatch, useSelector} from 'react-redux';
import {withRequiredAuthInfo} from '@propelauth/react';
import PropTypes from 'prop-types';

// Context
import {useDrawer} from '../../context/DrawerContext';

// Custom Components
import V1Drawer from '../../components/copilotV1/Drawer';
import {EmptyDocument} from '../../components/report-workspace/EmptyDocumentLoading';

// Collaboration
import {TiptapCollabProvider} from '@hocuspocus/provider'
import {getCollabSocket} from '../../util/collabSocketManager';
import * as Y from 'yjs'

// PDF
import {useReactToPrint} from 'react-to-print';

// MUI
import {
  Box,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';

// Icons
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

// Stylesheets
import './DocumentStyles.css'
import '@liveblocks/react-comments/styles.css';

// Utils
import {useHighlightEventListener} from '../../components/report-workspace/comment-utils';
import {COPILOT_DRAWER_WIDTH, GREY_70} from '../../App'
import {BLACK_100, WHITE_100} from '../../theme';
import {hideActionSuccess as hideActionSuccessV1, hideReviewComplete as hideReviewCompleteV1, hideActionError as hideActionErrorV1, setDocumentId as copilotSetDocumentIdV1, reset as copilotResetV1} from '../../data/copilotV1';
import {hideActionSuccess, hideReviewComplete, hideActionError, setDocumentId as copilotSetDocumentId, reset as copilotReset} from '../../data/copilot';
import _ from 'lodash';
import {useLocation, useParams, useNavigate} from 'react-router-dom';
import {withLDConsumer} from 'launchdarkly-react-client-sdk';
import {
  useGetDocumentQuery,
  useGetDocumentCollabTokenQuery,
  useCreateSupportingDocumentsDownloadUrlMutation,
} from '../../data/documentWorkspaceApi';
import {setDocument} from '../../data/documents';
import Error from '../../components/Error';
import NoData from '../../components/NoData';
import Loading from '../../components/Loading';
import {sleep, trackEvent, triggerDownload} from '../../util';

const LazyEditor = React.lazy(() => import('./DocumentEditor'));

const styles = {
  review: {
    container: {
      bgcolor: BLACK_100,
      borderRadius: '0.5rem',
    },
    header: {
      color: BLACK_100,
      fontSize: '0.875rem',
      fontWeight: 700,
      lineHeight: '1.25rem',
    },
    label: {
      color: BLACK_100,
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: '1.25rem',
    },
    boldLabel: {
      color: BLACK_100,
      fontSize: '0.75rem',
      fontWeight: 700,
      lineHeight: '1.25rem',
    },
  },
}

const CollaborativeDocument = (props) => {
  const {documentId} = useParams();
  const {currentOrg, isAuthenticated} = useSelector((state) => state.session);
  const {collabToken} = useSelector((state) => state.documents);
  const collabSocket = getCollabSocket();
  const shouldFetchToken = documentId && currentOrg && isAuthenticated && !collabToken;

  const {data: {token} = {}, isFetching, isError} = useGetDocumentCollabTokenQuery(
    {documentId},
    {skip: !shouldFetchToken},
  );

  const [doc, setDoc] = useState(null);
  const [provider, setProvider] = useState(null);

  useEffect(() => {
    if (isFetching || (!token && !collabToken)) return;

    const documentName = `${currentOrg?.orgId}:${documentId}`;
    const yDoc = new Y.Doc();
    const yProvider = new TiptapCollabProvider({
      websocketProvider: collabSocket,
      name: documentName,
      document: yDoc,
      token: collabToken || token,
    });

    setDoc(yDoc);
    setProvider(yProvider);

    return () => {
      yProvider.disconnect();
    };
  }, [documentId, token, collabToken]);

  if (!doc || !provider || isError) {
    return <Error hasError={isError} message="There was an error loading your document. Please try again later." />;
  }

  return <Document {...props} provider={provider} doc={doc} />;
};

const Document = ({provider, doc, accessHelper, flags}) => {
  const {documentId} = useParams();
  const {currentOrg, isAuthenticated} = useSelector((state) => state.session);
  const {documentId: copilotDocumentId} = useSelector((state) => state.copilot);
  const {document: documentStorage} = useSelector((state) => state.documents)
  const {copilotActionSuccess: copilotActionSuccessV1, copilotReviewComplete: copilotReviewCompleteV1, copilotActionMessage: copilotActionMessageV1, copilotActionError: copilotActionErrorV1, copilotErrorMessage: copilotErrorMessageV1} = useSelector((state) => state.copilotV1);
  const {copilotActionSuccess, copilotReviewComplete, copilotActionMessage, copilotActionError, copilotErrorMessage} = useSelector((state) => state.copilot);
  const dispatch = useDispatch();
  const isFinal = useSelector((state) => state.documents.document.status === 'FINAL');

  const canEdit =
    accessHelper.hasPermission(currentOrg.orgId, 'can_edit_reports') &&
    !isFinal;

  const {data: documentData, isFetching: documentIsFetching, isError: documentIsError, error: documentError, isSuccess: documentIsSuccess, refetch: documentRefetch} = useGetDocumentQuery({documentId}, {skip: _.isNil(documentId) || _.isNil(currentOrg) || !isAuthenticated, refetchOnMountOrArgChange: true});

  // Drawers
  const {drawerStates, openDrawer, closeDrawer} = useDrawer();
  const copilot = useSelector(state => state.copilot);

  const [isLoadingDocument, setIsLoadingDocument] = useState(true);

  // Printing state
  const [isPrinting, setIsPrinting] = useState(false);
  const [isPrintContentReady, setIsPrintContentReady] = useState(false);
  const [isPreparingPrint, setIsPreparingPrint] = useState(false);

  // Refs
  const contentToPrintRef = useRef(null);

  const [hasLoadedDocument, setHasLoadingDocument] = useState(false);
  const _location = useLocation();
  const _navigate = useNavigate();
  const _queryParams = new URLSearchParams(_location.search);
  const _loadSupportingDocuments = _queryParams.get('d') === 't';
  const [createSupportingDocumentsDownloadUrl, {data: supportingDocumentsData, isSuccess: supportingDocumentsIsSuccess, isError: supportingDocumentsIsError, error: supportingDocumentsError}] = useCreateSupportingDocumentsDownloadUrlMutation();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  if (_.isNil(documentId)) {
    // Redirect to documents page if documentId is not provided. Should never happen.
    this.props.history.replace('/documents');
  }

  useEffect(() => {
    if (documentIsSuccess && documentData.record.documentId !== documentStorage.documentId) {
      dispatch(setDocument({document: documentData.record}));
    }
  }, [documentIsSuccess, documentData]);

  useEffect(() => {
    if (_loadSupportingDocuments && !hasLoadedDocument) {
      const _startTime = new Date().getTime();
      setHasLoadingDocument(true);
      createSupportingDocumentsDownloadUrl({documentId});
      trackEvent('Supporting Documents Loaded', {documentId, duration: new Date().getTime() - _startTime});
    }
  }, [documentData, hasLoadedDocument, _loadSupportingDocuments]);

  useEffect(() => {
    if (supportingDocumentsIsSuccess) {
      triggerDownload(() => ({signedUrl: supportingDocumentsData.supportingDocumentsSignedUrl}), `${documentData.record.name} - [Supporting Documents].zip`);
    } else if (supportingDocumentsIsError) {
      console.error('Error downloading supporting document', {error: supportingDocumentsError});
      setErrorMessage('There was an error downloading the supporting documents. Please try again later.');
      setShowErrorMessage(true);
    }
    _navigate(`/documents/${documentId}`);
  }, [supportingDocumentsData, supportingDocumentsIsSuccess, supportingDocumentsIsError, supportingDocumentsError]);

  useEffect(() => {
    if (copilotActionErrorV1) {
      setErrorMessage(copilotErrorMessageV1);
      setShowErrorMessage(true);
    }
  }, [copilotErrorMessageV1, copilotActionErrorV1])

  useEffect(() => {
    if (copilotActionError) {
      setErrorMessage(copilotErrorMessage);
      setShowErrorMessage(true);
    }
  }, [copilotErrorMessage, copilotActionError])

  useEffect(() => {
    if (provider.isSynced) {
      setIsLoadingDocument(false);
    } else {
      provider.on('synced', () => {
        console.log('Document synced successfully.');
        setIsLoadingDocument(false);
      });

      provider.on('status', ({status}) => {
        if (status === 'connected') {
          console.log('Connected to the collaboration provider.');
        }
      });
    }

    return () => {
      provider.off('synced');
      provider.off('status');
    };
  }, [provider]);

  useEffect(() => {
    if (!documentIsFetching) {
      if (documentIsError) {
        if (documentError.status !== 401) {
          trackEvent('Document Metadata Load Failed', {documentId, status: documentError.status, refetch: false});
        } else {
          trackEvent('Document Metadata Load Failed', {documentId, status: documentError.status, refetch: true});
          sleep(500).then(() => {
            documentRefetch();
          })
        }
      }
      if (documentIsSuccess) {
        if (documentId !== copilotDocumentId) {
          if (_.get(flags, 'copilotV2', false)) {
            dispatch(copilotReset());
            dispatch(copilotSetDocumentId({documentId}));
          } else {
            dispatch(copilotResetV1());
            dispatch(copilotSetDocumentIdV1({documentId}));
          }
        }
        trackEvent('Document Metadata Load Completed', {documentId});
      }
    }
  }, [documentIsSuccess, documentIsError, documentIsFetching]);

  useHighlightEventListener((highlightId, isClick) => {
    if (isClick && !isFinal) openDrawer('comments');
  });

  const enterPrintMode = useCallback(() => {
    setIsPreparingPrint(true);
    setTimeout(() => setIsPrinting(true), 100);
  }, []);

  const exitPrintMode = useCallback(() => {
    setIsPrinting(false);
    setIsPreparingPrint(false);
    setIsPrintContentReady(false);
  }, []);

  const handlePrint = useReactToPrint({
    documentTitle: _.get(documentData, 'record.name'),
    removeAfterPrint: true,
    content: () => contentToPrintRef.current,
    onAfterPrint: exitPrintMode,
  });

  const onPrintContentReady = useCallback(() => {
    setIsPrintContentReady(true);
  }, []);

  useEffect(() => {
    if (isPrinting && isPrintContentReady) {
      setIsPreparingPrint(false);
      handlePrint();
    }
  }, [isPrinting, isPrintContentReady, handlePrint]);

  const _getErrorState = () => {
    switch (documentError.status) {
      case 404:
        return <NoData hidden={!documentIsError} screen={'document'} />
      default:
        return <Error hasError={documentIsError} message={'There was an error loading your document. Please try again later.'} />
    }
  }

  const _hideActionError = () => {
    if (_.get(flags, 'copilotV2', false)) {
      dispatch(hideActionError());
    } else {
      dispatch(hideActionErrorV1());
    }
    setShowErrorMessage(false);
    setErrorMessage(null);
  }

  const _hideActionSuccess = () => {
    if (_.get(flags, 'copilotV2', false)) {
      dispatch(hideActionSuccess());
    } else {
      dispatch(hideActionSuccessV1());
    }
  }

  const _hideReviewComplete = () => {
    if (_.get(flags, 'copilotV2', false)) {
      dispatch(hideReviewComplete());
    } else {
      dispatch(hideReviewCompleteV1());
    }
  }

  return (
    <React.Fragment>
      <Stack direction='column' width='100%' height='100vh' bgcolor={GREY_70}>
        <Loading
          loading={isPreparingPrint}
          message={'Preparing PDF for print'}
          loadingProps={{size: '46'}}
          containerProps={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            bgcolor: GREY_70,
            zIndex: 1000,
            pt: '45px',
          }}
        />

        <EmptyDocument loading={isLoadingDocument} />

        {!isLoadingDocument && documentIsError && (
          <Box display={'flex'} flex={1} height={'100%'} alignItems={'center'}>
            {_getErrorState()}
          </Box>
        )}

        {!isLoadingDocument && !documentIsError &&
          <React.Fragment>
            <React.Suspense fallback={<EmptyDocument />}>
              <LazyEditor
                provider={provider}
                doc={doc}
                documentId={documentId}
                canEdit={canEdit}
                isFinal={isFinal}
                contentToPrintRef={contentToPrintRef}
                isPrinting={isPrinting}
                onPrintContentReady={onPrintContentReady}
                enterPrintMode={enterPrintMode}
                flags={flags}
              />
            </React.Suspense>
            {!_.get(flags, 'copilotV2', false) && !isFinal && (
              <V1Drawer
                onClose={() => closeDrawer('copilot')}
                open={drawerStates.copilot}
                flags={flags}
              />
            )}
          </React.Fragment>
        }
      </Stack>
      <Snackbar
        open={copilotActionSuccess || copilotActionSuccessV1}
        autoHideDuration={5000}
        onClose={_hideActionSuccess}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        sx={{marginRight: drawerStates.copilot ? COPILOT_DRAWER_WIDTH : 0}}
        ClickAwayListenerProps={{onClickAway: () => null}}
      >
        <Stack direction={'row'} sx={{backgroundColor: WHITE_100, borderRadius: '0.5rem', boxShadow: '0px 1px 5px 0px rgba(11, 14, 12, 0.15);'}} width={'16.25rem'} height={'3rem'} paddingX={'1rem'} alignItems={'center'}>
          <Box marginRight={'0.75rem'} display={'flex'} justifyContent={'center'} alignItems={'center'} padding={'0.25rem'} height={'1.25rem'} width={'1.25rem'} borderRadius={'50%'} sx={{background: 'linear-gradient(0deg, #33B980 0%, #33B980 100%), linear-gradient(180deg, rgba(49, 197, 125, 0.70) 0%, #3B768B 100%), #F1F4F2;'}}>
            <CheckIcon fontSize={'12px'} sx={{color: WHITE_100}}/>
          </Box>
          <Typography variant='body1'>{!_.isNil(copilotActionMessageV1) ? copilotActionMessageV1 : !_.isNil(copilotActionMessage) ? copilotActionMessage : 'Item resolved and closed'}</Typography>
        </Stack>
      </Snackbar>
      <Snackbar
        open={showErrorMessage}
        autoHideDuration={5000}
        onClose={_hideActionError}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        sx={{marginRight: drawerStates.copilot ? COPILOT_DRAWER_WIDTH : 0}}
        ClickAwayListenerProps={{onClickAway: () => null}}
      >
        <Stack direction={'row'} sx={{backgroundColor: WHITE_100, borderRadius: '0.5rem', boxShadow: '0px 1px 5px 0px rgba(11, 14, 12, 0.15);'}} width={'16.25rem'} minHeight={'3rem'} paddingX={'1rem'} paddingY={'0.5rem'} alignItems={'center'}>
          <ErrorOutlineIcon sx={{color: '#D73E3E', fontSize: '1.25rem', marginRight: '0.75rem'}}/>
          <Typography variant='body1'>{!_.isNil(errorMessage) ? errorMessage : 'There was an error processing your request. Please try again later.'}</Typography>
        </Stack>
      </Snackbar>
      <Snackbar
        open={copilotReviewComplete || copilotReviewCompleteV1}
        autoHideDuration={5000}
        onClose={_hideReviewComplete}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        sx={{marginRight: drawerStates.copilot ? COPILOT_DRAWER_WIDTH : 0}}
        ClickAwayListenerProps={{onClickAway: () => null}}
      >
        <Stack direction={'column'} spacing={'0.125rem'} sx={{flexDirection: 'column', backgroundColor: WHITE_100, borderRadius: '0.5rem', boxShadow: '0px 1px 5px 0px rgba(11, 14, 12, 0.15);'}} width={'16.25rem'} paddingX={'1rem'} paddingY={'0.5rem'}>
          <Typography style={styles.review.header}>Review complete!</Typography>
          <Stack direction={'row'}><Typography style={styles.review.label}>You have <span style={styles.review.boldLabel}>{copilot.numOpenItems} items</span> to resolve.</Typography></Stack>
        </Stack>
      </Snackbar>
    </React.Fragment>
  );
}

Document.propTypes = {
  session: PropTypes.object.isRequired,
  accessHelper: PropTypes.shape({
    hasPermission: PropTypes.func,
  }).isRequired,
  flags: PropTypes.shape({
    copilotV2: PropTypes.bool.isRequired,
    serverSideLinking: PropTypes.bool.isRequired,
  }),
  provider: PropTypes.object.isRequired,
  doc: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
  }
}

export default withLDConsumer()(connect(mapStateToProps)(withRequiredAuthInfo(CollaborativeDocument)));
