import {createClient} from '@liveblocks/client';
import {createRoomContext, createLiveblocksContext} from '@liveblocks/react';
import store from './store';

const baseUrl = process.env.REACT_APP_LIVEBLOCKS_API_URL;

const client = createClient({
  authEndpoint: async (room) => {
    const {session} = store.getState();

    if (!session || !session.accessToken) {
      throw new Error('Authorization token is missing');
    }

    const headers = {
      Authorization: `Bearer ${session.accessToken}`,
      'x-inscope-org': session.currentOrg.orgId,
      'Content-Type': 'application/json',
    };

    const body = JSON.stringify({room});
    const response = await fetch(`${baseUrl}/api/liveblocksAuth`, {
      method: 'POST',
      headers,
      body,
    });

    return await response.json();
  },
  throttle: 100,
  async resolveUsers ({userIds}) {
    // Used only for Comments and Notifications. Return a list of user information
    // retrieved from `userIds`. This info is used in comments, mentions etc.
    // Return in same order as userIds argument, and include only the meta info
    const {session} = store.getState();

    if (!session || !session.accessToken) {
      throw new Error('Authorization token is missing');
    }

    const headers = {
      Authorization: `Bearer ${session.accessToken}`,
      'x-inscope-org': session.currentOrg.orgId,
      'Content-Type': 'application/json',
    };
    const searchParams = new URLSearchParams(
      userIds.map((userId) => ['userIds', userId]),
    );

    const response = await fetch(`${baseUrl}/api/liveblocksUsers?${searchParams}`, {
      method: 'GET',
      headers,
    });

    return await response.json();
  },
  async resolveMentionSuggestions ({text}) {
    // Used only for Comments. Return a list of userIds that match `text`.
    // These userIds are used to create a mention list when typing in the
    // composer.
    const {session} = store.getState();

    if (!session || !session.accessToken) {
      throw new Error('Authorization token is missing');
    }

    const headers = {
      Authorization: `Bearer ${session.accessToken}`,
      'x-inscope-org': session.currentOrg.orgId,
      'Content-Type': 'application/json',
    };

    const response = await fetch(`${baseUrl}/api/liveblocksUsers/search?text=${encodeURIComponent(text)}`, {
      method: 'GET',
      headers,
    });

    return await response.json();
  },
  async resolveRoomsInfo ({roomIds}) {
    // Used only for Comments and Notifications. Return a list of room information
    // retrieved from `roomIds`.
    return [];
  },
});

// Room-level hooks, use inside `RoomProvider`
export const {
  suspense: {
    RoomProvider,
    useRoom,
    useMyPresence,
    useUpdateMyPresence,
    useSelf,
    useOthers,
    useOthersMapped,
    useOthersListener,
    useOthersConnectionIds,
    useOther,
    useBroadcastEvent,
    useEventListener,
    useErrorListener,
    useStorage,
    useObject,
    useMap,
    useList,
    useBatch,
    useHistory,
    useUndo,
    useRedo,
    useCanUndo,
    useCanRedo,
    useMutation,
    useStatus,
    useLostConnectionListener,
    useThreads,
    useCreateThread,
    useEditThreadMetadata,
    useCreateComment,
    useEditComment,
    useDeleteComment,
    useAddReaction,
    useRemoveReaction,
    useThreadSubscription,
    useMarkThreadAsRead,
    useRoomNotificationSettings,
    useUpdateRoomNotificationSettings,

    // These hooks can be exported from either context
    // useUser,
    // useRoomInfo
  },
} = createRoomContext(client);

// Project-level hooks, use inside `LiveblocksProvider`
export const {
  suspense: {
    LiveblocksProvider,
    useMarkInboxNotificationAsRead,
    useMarkAllInboxNotificationsAsRead,
    useInboxNotifications,
    useUnreadInboxNotificationsCount,

    // These hooks can be exported from either context
    useUser,
    useRoomInfo,
  },
} = createLiveblocksContext(client);
